import React, { useState } from 'react';
import { Form, Select, Button, message } from 'antd';
import Layout from 'components/layout';
import axios from 'utils/axios';

const { Option } = Select;

function Exports() {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleExport = async (values) => {
        setLoading(true);
        try {
            const response = await axios.get(`/api/export/${values.type}`, {
                responseType: 'blob'
            });
            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${values.type}_export.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            
            message.success('Export został pobrany pomyślnie');
        } catch (error) {
            message.error('Wystąpił błąd podczas exportu');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout>
            <Form
                form={form}
                onFinish={handleExport}
                layout="vertical"
                style={{ maxWidth: 400, margin: '20px auto' }}
            >
                <Form.Item
                    name="type"
                    label="Co chcesz wyeksportować?"
                    rules={[{ required: true, message: 'Wybierz typ exportu' }]}
                >
                    <Select placeholder="Wybierz typ exportu">
                        <Option value="customers">Klienci</Option>
                        <Option value="products">Produkty</Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} block>
                        Exportuj do CSV
                    </Button>
                </Form.Item>
            </Form>
        </Layout>
    );
}

export default Exports; 