import React, {useEffect, useState} from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import OrderForm from 'forms/order';

import {
    Tabs,
    Button,
    Modal,
    Skeleton,
    Dropdown,
    Menu,
    Space,
    Typography,
    Collapse, Spin
} from 'antd';
import { DownOutlined, CheckCircleOutlined } from '@ant-design/icons';
import {useParams} from "react-router-dom";
import ToolsList from "components/tools-list";
import DocumentsList from "components/documents-list";
import CompleteOrder from "modal/complete-order";
import axios from "utils/axios";
import {orderUtils} from "../utils/order-utils";
import history from "../utils/history";
import {v4 as uuidv4} from "uuid";

const {Text} = Typography;
const {TabPane} = Tabs;
const { Panel } = Collapse;

function OrderDetails() {
    const [showModal, setShowModal] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [loadingDownloadPdfButton, setLoadingDownloadPdfButton] = useState(false);
    const [loadingSendPdfButton, setLoadingSendPdfButton] = useState(false);
    const [documentSent, setDocumentSent] = useState(false);
    const [order, setOrder] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [moreTime, setMoreTime] = useState(false);
    const [tries, setTries] = useState(0);
    const {id} = useParams();

    const handleCloseCompletionOrder = () => {
        setProcessing(true);
        setTimeout(() => {
            fetchOrder();
        }, 2000);
    };

    function downloadPdf(id) {
        setLoadingDownloadPdfButton(true);
        orderUtils.downloadPZPdf(id, () => setLoadingDownloadPdfButton(false), () => setLoadingDownloadPdfButton(false));
    }

    function sendPdf(id) {
        setLoadingSendPdfButton(true);
        orderUtils.sendPZPdf(id, () => {
            setLoadingSendPdfButton(false);
            setDocumentSent(true);
        }, () => setLoadingSendPdfButton(false));
    }

    function fetch() {
        return axios.get(`/api/query/get-order?orderId=${id}`);
    }

    function fetchOrder(_try = 0) {
        if (_try === 3) {
            // if (localStorage.getItem('last_order')) {
            //     const orderPayload = JSON.parse(localStorage.getItem('last_order'));
            //     if (orderPayload.orderId === id) {
            //         orderPayload.orderId = uuidv4();
            //
            //         localStorage.setItem('last_order', JSON.stringify(orderPayload));
            //
            //         axios
            //             .post('/api/commands/order-create', orderPayload)
            //             .then(response => {
            //                 setProcessing(false);
            //                 window.location.href = `/orders/${orderPayload.orderId}`;
            //             })
            //             .catch(error => console.log(error))
            //         ;
            //     }
            // }
            console.log('remove and recreate and fetch again');
        } else {
            fetch().then(response => {
                if (response.data.order) {
                    setOrder(response.data);
                    setProcessing(false);
                } else {
                    setMoreTime(true);
                    setTimeout(() => fetchOrder(_try+1), 5000)
                }
            });
        }
    }

    useEffect(() => {
        fetchOrder();
    }, []);

    useEffect(() => {
        if (order) {
            setDocumentSent(parseInt(order.order.email_sent));
        }
    }, [order]);

    if (moreTime) {
        return (<p>Wczytywanie może potrwać chwilę dłużej... Proszę czekać.</p>)
    }

    if (!order) {
        return (
            <Layout>
                <Skeleton />
            </Layout>
        );
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => downloadPdf(id)}>Pobierz</Menu.Item>
            <Menu.Item key="2" onClick={() => sendPdf(id)} disabled={documentSent || !order.order.customer_send_email}>Wyślij do klienta</Menu.Item>
        </Menu>
    );

    return (
        <Layout
            headerRightSide={() => <>
                <Button type="primary" onClick={() => setShowModal(true)}>
                    Zrealizuj zamówienie
                </Button>
                <span style={{display: 'inline-block', width: 10}}/>
                <Space>
                    <Dropdown overlay={menu}>
                        <Button loading={loadingDownloadPdfButton || loadingSendPdfButton}>
                            Dokument PZ <DownOutlined />
                        </Button>
                    </Dropdown>

                    {documentSent ? (
                        <Text>
                            <Space>
                                <CheckCircleOutlined style={{color: '#6ed349'}} />
                                <span style={{color: '#6ed349'}}>Dokument został wysłany</span>
                            </Space>
                        </Text>
                    ) : null}

                    {!documentSent && order.order.customer_send_email ? (
                        <Text>
                            <Space>
                                <CheckCircleOutlined style={{color: '#a01717'}} />
                                <span style={{color: '#a01717'}}>Dokument nie został wysłany</span>
                            </Space>
                        </Text>
                    ) : null}
                </Space>
            </>}
        >
            <Tabs defaultActiveKey={activeTab} onChange={(activeKey) => setActiveTab(activeKey)}>
                <TabPane tab="Szczegóły" key="1">
                    <Collapse style={{marginBottom: 20}}>
                        <Panel header={`[${order.order.customer_name}] Id zamówienia: ${order.order.order_index} | E-mail: ${order.order.customer_email}`} key="order">
                            <OrderForm data={order} />
                        </Panel>
                    </Collapse>

                    <ToolsList data={order.products} />
                </TabPane>
                <TabPane tab="Realizacje" key="3">
                    <Spin spinning={processing}>
                        <DocumentsList data={order.documents} countProducts={order.products.length} triggerRefresh={fetchOrder} />
                    </Spin>
                </TabPane>
            </Tabs>

            <Modal
                visible={showModal}
                destroyOnClose={true}
                width="90%"
                title="Zrealizuj zamówienie"
                footer={null}
                closeIcon={true}
            >
                <CompleteOrder order={order} close={() => setShowModal(false)} afterClose={handleCloseCompletionOrder}/>
            </Modal>
        </Layout>
    )
}

export default requiredAuth(OrderDetails);
