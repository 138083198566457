import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Provider from 'utils/store-provider';
import store from 'utils/store';
import {appActions} from "action/app";
import {Route, Router, Switch} from "react-router";
import History from 'utils/history';

import Login from "screen/Login";
import Dashboard from "screen/Dashboard";
import Pickup from "screen/Pickup";
import CreateOrder from "screen/CreateOrder";
import OrderDetails from "screen/OrderDetails";
import Sharping from "screen/Sharping";
import Reports from "screen/Reports";
import Workers from "screen/Workers";
import WorkerDetails from "screen/WorkerDetails";
import WorkerChangePassword from "screen/WorkerChangePassword";
import CreateWorker from "screen/CreateWorker";
import Customers from "screen/Customers";
import CreateCustomer from "screen/CreateCustomer";
import CustomerDetails from "screen/CustomerDetails";
import Regions from "screen/Regions";
import CreateRegion from "screen/CreateRegion";
import RegionDetails from "screen/RegionDetails";
import Categories from "screen/Categories";
import CreateCategory from "screen/CreateCategory";
import CategoryDetails from "screen/CategoryDetails";
import OfflineScreen from "offline/screen";

import Products from "screen/Products";
import ProductDetails from "screen/ProductDetails";
import CreateProduct from "screen/CreateProduct";
import Diameters from "screen/Diameters";

import Exports from "screen/Exports";

import { ConfigProvider } from 'antd';
import plPL from 'antd/es/locale/pl_PL';


import moment from "moment";
import 'moment/locale/pl';
import 'antd/dist/antd.css';
import Import from "./screen/Import";

moment.locale('pl');

store.dispatch(appActions.initialize());
store.dispatch(appActions.fetchDiameters());
function Loading(props) {
    const [finished, setFinished] = useState(false);

    if (!window.navigator.onLine && !window.location.href.match(/\/offline/g)) {
        window.location.href = '/offline';
    }

    const subscriber = store.subscribe(() => {
        if (store.getState().app.__initialized__) {
            setFinished(true);
            subscriber();
        }
    });

    if (finished) {
        return props.children;
    }

    return <div>Loading...</div>;
}

function LogoutScreen() {
    localStorage.removeItem('auth_credentials');
    window.location = '/';
}

ReactDOM.render(
  <React.StrictMode>
      <ConfigProvider locale={plPL}>
          <Provider>
              <Loading>
                  <Router history={History}>
                      <Switch>
                          <Route path="/" component={Dashboard} exact={true} />
                          <Route path="/sharping" component={Sharping} exact={true} />
                          <Route path="/reports" component={Reports} exact={true} />
                          <Route path="/pickup" component={Pickup} exact={true} />

                          <Route path="/orders/new" component={CreateOrder} exact={true} />
                          <Route path="/orders/:id" component={OrderDetails} exact={true} />

                          <Route path="/login" component={Login} exact={true} />
                          <Route path="/workers" component={Workers} exact={true} />
                          <Route path="/workers/new" component={CreateWorker} exact={true} />
                          <Route path="/workers/:id" component={WorkerDetails} exact={true} />
                          <Route path="/workers/:id/change-password" component={WorkerChangePassword} exact={true} />

                          <Route path="/customers" component={Customers} exact={true} />
                          <Route path="/customers/new" component={CreateCustomer} exact={true} />
                          <Route path="/customers/:id" component={CustomerDetails} exact={true} />

                          <Route path="/regions" component={Regions} exact={true} />
                          <Route path="/regions/new" component={CreateRegion} exact={true} />
                          <Route path="/regions/:id" component={RegionDetails} exact={true} />

                          <Route path="/categories" component={Categories} exact={true} />
                          <Route path="/categories/new" component={CreateCategory} exact={true} />
                          <Route path="/categories/:id" component={CategoryDetails} exact={true} />

                          <Route path="/products" component={Products} exact={true} />
                          <Route path="/products/new" component={CreateProduct} exact={true} />
                          <Route path="/products/:id" component={ProductDetails} exact={true} />
                          <Route path="/diameters" component={Diameters} exact={true} />

                          <Route path="/offline" component={OfflineScreen} exact={true} />
                          <Route path="/logout" component={LogoutScreen} exact={true} />

                          <Route path="/exports" component={Exports} exact={true} />
                          <Route path="/import" component={Import} exact={true} />
                      </Switch>
                  </Router>
              </Loading>
          </Provider>
      </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
