import React, { useState, useEffect } from 'react';
import { Modal, Input, Form, message } from 'antd';

export default function PinConfirmationModal({ visible, onOk, onCancel }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      // Small delay to ensure modal is rendered
      setTimeout(() => {
        const input = form.getFieldInstance('pin');
        if (input) {
          input.focus();
        }
      }, 100);
    }
  }, [visible]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      onOk(values.pin, () => {
        form.setFieldValue('pin', '');
        setLoading(false);
      });
    } catch (error) {
      // Form validation error
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title="Potwierdź PIN"
      open={visible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      <Form 
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="pin"
          rules={[
            { required: true, message: 'Proszę podać PIN' },
            { len: 4, message: 'PIN musi mieć dokładnie 4 cyfry' },
            { pattern: /^[0-9]*$/, message: 'PIN może zawierać tylko cyfry' }
          ]}
        >
          <Input.Password 
            maxLength={4}
            placeholder="Wprowadź swój 4-cyfrowy PIN"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
} 