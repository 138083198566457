import React, {useState} from "react";
import {Button, Col, Form, Row, Input, notification} from "antd";
import { v4 as uuidv4 } from 'uuid';
import axios from 'utils/axios';
import history from 'utils/history';
import RolesSelect from "components/roles-select";
import RegionSelect from "components/region-select";

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function Worker(props) {
    const {data} = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        setLoading(true);

        if (data) {
            axios
                .post('/api/commands/change-user', {
                    user_id: data.id,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    roles: values.roles,
                    phone_number: values.phone_number,
                    pin: values.pin
                })
                .then(response => {
                    notification['success']({
                        message: 'Dane zostały zapisane!',
                    });
                    setLoading(false);
                })
                .catch(error => console.log(error))
            ;
        } else {
            const payload = {
                user_id: uuidv4(),
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                password: values.password,
                roles: values.roles,
                phone_number: values.phone_number,
                pin: values.pin
            };

            axios
                .post('/api/commands/register', payload)
                .then(response => {
                    history.push(`/workers/${payload.user_id}`);
                })
                .catch(error => console.log(error))
            ;
        }
    };

    return (
        <Row>
            <Col span={12} push={6}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={data}
                >
                    <Form.Item name="first_name" label="Imię" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="last_name" label="Nazwisko" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="email" label="Adres e-mail" rules={[{ required: true }]}>
                        <Input disabled={!!data} />
                    </Form.Item>

                    <Form.Item name="phone_number" label="Numer telefonu" rules={[{ required: false }]}>
                        <Input />
                    </Form.Item>

                    {data ? null : (
                        <Form.Item name="password" label="Hasło" rules={[{ required: true, min: 5 }]}>
                            <Input.Password />
                        </Form.Item>
                    )}

                    <Form.Item name="roles" label="Funkcja" rules={[{ required: true }]}>
                        <RolesSelect />
                    </Form.Item>

                    <Form.Item 
                        name="pin" 
                        label="PIN" 
                        rules={[
                            { required: true, message: 'Proszę podać PIN' },
                            { len: 4, message: 'PIN musi mieć dokładnie 4 cyfry' },
                            { pattern: /^[0-9]*$/, message: 'PIN może zawierać tylko cyfry' }
                        ]}
                    >
                        <Input.Password maxLength={4} />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {data ? 'Zapisz' : 'Utwórz pracownika'}
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
