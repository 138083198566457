import axios from 'utils/axios';
import store from 'utils/store';

export const appActions = {
    initialize,
    fetchDiameters,
    setDiameters,
    repairTool,
};

function repairTool(tool) {
    // request: /api/query/get-product-repair-query?productDefinitionId=6083cfe4-1693-4fee-8530-1ff2ab503521
    const order = {
        tool,
        repair: null
    };
    const r = {
        customer_id: tool.customer_id,
        payment_method: tool.payment_method,
        order_deadline: tool.order_deadline,
        products: [{
            id: tool.definition_id,
            diameter: tool.diameter,
            hole: tool.hole,
            notes: `ze zlecenia ${tool.order_index}`,
        }],
    };
    axios
        .get(`/api/query/product-repair?productDefinitionId=${tool.definition_id}`)
        .then(response => {
            if (response.data.id) {
                r.products.push({
                    id: response.data.id,
                    diameter: tool.diameter,
                    hole: tool.hole,
                    notes: `ze zlecenia ${tool.order_index}`,
                });
            }

            window.location.href = `/orders/new?data=${new Buffer(JSON.stringify(r)).toString('base64')}`;
        })
    ;
}

function initialize(success = null) {
    return function (dispatch) {
        axios
            .get('/api/me')
            .then(response => {
                dispatch({type: 'INITIALIZE', data: response.data});
                success && success();
            })
            .catch(error => {
                dispatch({type: 'INITIALIZE', data: null});
                success && success();
            })
        ;
    }
}

function fetchDiameters(success = null) {
    return function (dispatch) {
        const i = (success) => {
            axios
                .get(`/api/query/find-configuration?name=diameters`)
                .then(response => {
                    dispatch(setDiameters(response.data.value));
                    success && success();
                })
                .catch(r => {
                    if (r.response.status === 500) {
                        axios
                            .post(`/api/commands/create-configuration`, {
                                name: 'diameters',
                                value: '',
                            })
                            .then(_ => {
                                dispatch(setDiameters([]));
                                success && success();
                            })
                    } else {
                        setTimeout(() => i(success), 2000);
                    }
                })
            ;
        };

        i(success);
    }
}

function setDiameters(diameters) {
    return {
        type: 'FETCH_DIAMETERS',
        data: diameters,
    };
}