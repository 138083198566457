import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import axios from 'utils/axios';

export default function UsersSelect(props) {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (users.length === 0) {
            axios
                .get('/api/query/all-users')
                .then(response => {
                    setUsers(response.data);
                    localStorage.setItem('users', JSON.stringify(response.data));
                });
        }
    }, []);

    const data = users.length === 0 && localStorage.getItem('users') ? JSON.parse(localStorage.getItem('users')) : users;

    return (
        <Select
            placeholder="Wybierz pracownika"
            loading={users.length === 0}
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            {...props}
        >
            {data.sort((a, b) => a.first_name.localeCompare(b.first_name)).map(user => <Select.Option value={user.id}>{`${user.first_name} ${user.last_name} (${user.email})`}</Select.Option>)}
        </Select>
    )
}
