import React, {useState} from 'react';
import {Select} from "antd";
import axios from 'utils/axios';

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            definitions: []
        };
    }

    componentDidMount() {
        axios
            .get('/api/query/all-product-definitions')
            .then(response => {
                this.setState({definitions: response.data});
                localStorage.setItem('_definitions', JSON.stringify(response.data));
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot): void {
        if (prevProps.defaultValue !== this.props.defaultValue) {
            this.setState({
                loading: true
            });
            setTimeout(() => this.setState({loading: false}), 200);
        }
    }

    render() {
        const data = this.state.definitions.length === 0 && localStorage.getItem('_definitions') ? JSON.parse(localStorage.getItem('_definitions')) : this.state.definitions;

        if (this.state.loading) {
            return <span>Loading...</span>;
        }

        return (
            <Select
                placeholder="Wybierz produkt"
                loading={data.length === 0}
                filterOption={(input, option) => {
                    let name = '';
                    if (typeof option.children === 'string') {
                        name = option.children;
                    } else if (typeof option.children === 'object') {
                        name = Object.values(option.children).join(' ');
                    }
                    return name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                showSearch
                {...this.props}
            >
                {data
                    .filter(definition => !this.props.currentProductId || definition.id !== this.props.currentProductId)
                    .map(definition => (
                        <Select.Option key={definition.id} value={definition.id}>
                            {definition.name} ({definition.symbol})
                        </Select.Option>
                    ))
                }
            </Select>
        )
    }
}
