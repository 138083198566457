import React, {useContext, useEffect, useState} from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import config from 'config';
import axios from 'utils/axios';
import List from 'components/list';
import {Button, Switch, message, Dropdown, Space} from "antd";
import ProductStatusSelect from "components/product-status-select";
import OrderStatus from "components/order-status";
import AdditionalNotesInput from "components/additional-notes-input";
import ProductStatus from "components/order/ProductStatus";
import moment from "moment";
import Filters, {convertToQueryString, getFilters} from "utils/filters";
import ProductCategorySelect from "../components/product-category-select";
import {useQueryParam} from "../utils/use-query-param";
import OrderItemPhoto from "../components/order/order-item-photos";
import PinConfirmationModal from '../components/pin-confirmation-modal';
import {MoreOutlined} from "@ant-design/icons";
import {appActions} from "../action/app";
import styled from "styled-components";

const FloatingContainer = styled.section`
    width: 300px;
    background: #fff;
    position: fixed;
    right: 50px;
    bottom: 50px;
    padding: 20px;
    border-radius: 5px;
    z-index: 9999;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

function Sharping() {
    const [data, setData] = useState([]);
    const [page, setPage] = useQueryParam('page', 1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [printUrl, setPrintUrl] = useState('');
    const [pinModalVisible, setPinModalVisible] = useState(false);
    const [pendingStatusChange, setPendingStatusChange] = useState(null);
    const [selectionType, setSelectionType] = useState('checkbox');
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        fetchProducts(page, getFilters());
    }, []);

    useEffect(() => {
        console.log(selectedRows);
    }, [selectedRows]);

    function fetchProducts(page, filters = {}) {
        setLoading(true);
        setPage(page);

        const credentials = JSON.parse(localStorage.getItem('auth_credentials'));

        setPrintUrl(`${config.API_URL}/api/print-products-by-filters?page=-1${convertToQueryString(filters)}&access_token=${credentials.token.access_token}`);
        axios
            .get(`/api/query/get-products-by-filters?page=${page+convertToQueryString(filters)}`)
            .then(response => {
                setData(response.data.data);
                setLastPage(response.data.last_page);
                setLoading(false);
            })
        ;
    }

    const handleStatusChange = (productId, newStatus) => {
        setPendingStatusChange({ productId, newStatus });
        setPinModalVisible(true);
    };

    const handleStatusChangeMultiple = (productIds, newStatus) => {
        setPendingStatusChange({ productIds, newStatus });
        setPinModalVisible(true);
    };

    const handlePinConfirm = async (pin, finish) => {
        try {
            if (pendingStatusChange.productIds) {
                const ids = pendingStatusChange.productIds.map(p => p.id);
                await axios.post('/api/commands/change-product-status-batch', {
                    product_ids: ids.join('|'),
                    status: pendingStatusChange.newStatus,
                    pin: pin
                });
            } else {
                await axios.post('/api/commands/change-product-status', {
                    product_id: pendingStatusChange.productId,
                    status: pendingStatusChange.newStatus,
                    pin: pin
                });
            }
            
            message.success('Status został zmieniony');
            setPinModalVisible(false);
            fetchProducts(page, getFilters()); // Refresh the data
            finish && finish();
        } catch (error) {
            if (error.response?.status === 403) {
                message.error('Nieprawidłowy PIN');
            } else {
                message.error('Wystąpił błąd podczas zmiany statusu');
            }
            finish && finish();
        }
    };

    return (
        <Layout
            headerRightSide={() => <>
                <Button type="primary" onClick={() => window.open(printUrl)} style={{marginRight: 10}}>
                    Widok do wydruku
                </Button>

                <Filters
                    onChange={filters => fetchProducts(1, filters)}
                    renderBefore={[
                        {
                            name: 'productStatus',
                            defaultValue: undefined,
                            atIndex: 4,
                            render: (setFilter, defaultValue) => <ProductStatusSelect
                                defaultValue={defaultValue}
                                placeholder="Status narzędzia"
                                allowClear={true}
                                style={{width: 200, marginRight: 10}}
                                onChange={value => setFilter(value)}
                            />
                        },
                        {
                            name: 'categoryId',
                            defaultValue: undefined,
                            atIndex: 5,
                            render: (setFilter, defaultValue) => <ProductCategorySelect
                                defaultValue={defaultValue}
                                allowClear={true}
                                style={{width: 200, marginRight: 10}}
                                onChange={value => setFilter(value)}
                            />
                        }
                    ]}
                />
            </>}
        >
            <List
                name="sharping"
                fields={['order_index', 'diameter', 'hole', 'quantity', 'status', 'order_creation_date', 'order_deadline', 'notes', 'additional_notes', 'photo', 'action']}
                render={{
                    order_index: (value, product) => {
                        return (
                            <div>
                                <strong>[{product.order_index}] {product.customer_short_name}</strong><br/>
                                {product.name}
                            </div>
                        );
                    },
                    status: (value, product) => product.document_id ? <ProductStatus value={product.status} /> : <ProductStatusSelect defaultValue={product.status} onChange={value => handleStatusChange(product.id, value)} style={{width: 100}}/>,
                    order_status: (notes, product) => <OrderStatus value={product.order_status} />,
                    notes: (notes, product) => <span>{product.notes}</span>,
                    additional_notes: (notes, product) => product.document_id ? <span>{product.additional_notes}</span> : <AdditionalNotesInput defaultValue={notes} tool={product} />,
                    order_creation_date: (creation_date, product) => creation_date ? moment(creation_date).format('DD-MM-YYYY') : '-',
                    order_deadline: (deadline, product) => deadline ? moment(deadline).format('DD-MM-YYYY') : '-',
                    photo: (photo, tool) => <OrderItemPhoto
                        classNameWrapper="order-item-photos-wrapper--auto-width"
                        photos={tool.photo ? tool.photo : []}
                        append={true}
                        productId={tool.id}
                    />,
                    action: (_, tool) => {
                        const items = [
                            {
                                key: '1',
                                label: 'Wydrukuj zlecenie',
                                onClick: () => {
                                    window.open(`${config.API_URL}/printout/order?orderId=${tool.order_id}`);
                                },
                            },
                            {
                                key: '2',
                                label: 'Wydrukuj pozycje',
                                onClick: () => {
                                    window.open(`${config.API_URL}/printout/order?orderId=${tool.order_id}&productId=${tool.id}`);
                                },
                            },
                            {
                                key: '3',
                                label: 'Utwórz zlecenie naprawy',
                                onClick: () => appActions.repairTool(tool),
                            },
                        ];

                        return (
                            <Dropdown menu={{items}}>
                                <Button>
                                    <MoreOutlined />
                                </Button>
                            </Dropdown>
                        )
                    }
                }}
                fixed={{
                    order_index: 'left'
                }}
                ellipsis={['order_creation_date', 'order_deadline']}
                sizes={{
                    hole: 70,
                    diameter: 70,
                    quantity: 70,
                    status: 130,
                    order_creation_date: 130,
                    order_deadline: 130,
                    order_index: 300,
                    photo: 90,
                    action: 90,
                }}
                alignCenter={['hole', 'diameter', 'quantity', 'status']}
                scroll={{x: 1600}}
                labels={{
                    name: 'Narzędzie',
                    hole: 'F',
                    diameter: 'D',
                    order_id: 'Identyfikator zamówienia',
                    customer_short_name: 'Klient',
                    status: 'Status',
                    order_status: 'Status zamówienia',
                    notes: 'Uwagi od handlowców',
                    photo: 'Zdjęcie',
                    additional_notes: 'Uwagi od warsztatu',
                    quantity: 'Il.',
                    order_index: 'Narzędzie',
                    order_deadline: 'Termin realizacji',
                    order_creation_date: 'Data utworzenia zamówienia',
                    action: 'Opcje',
                }}
                hideActions={true}
                data={data}
                page={page}
                lastPage={lastPage}
                onChangePage={page => fetchProducts(page, getFilters())}
                loading={loading}
                tableProps={{
                    rowSelection: {
                        type: selectionType,
                        onChange: (selectedRowKeys, selectedRows) => {
                            setSelectedRows(selectedRows);
                        },
                    },
                    rowKey: 'id',
                }}
            />
            <PinConfirmationModal
                visible={pinModalVisible}
                onOk={handlePinConfirm}
                onCancel={() => setPinModalVisible(false)}
            />
            {
                selectedRows.length > 0 ? (
                    <FloatingContainer>
                        <p style={{margin: 0, marginBottom: 5, fontWeight: 'bold'}}>Zmień status:</p>
                        <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                            <Button style={{width: '66.7%'}} type="primary" onClick={() => handleStatusChangeMultiple(selectedRows, 'sharpened')}>Gotowe</Button>
                            <Button style={{width: '33.3%'}} onClick={() => handleStatusChangeMultiple(selectedRows, 'now_sharpened')}>Nowe</Button>
                        </div>
                    </FloatingContainer>
                ) : null
            }
        </Layout>
    )
}

export default requiredAuth(Sharping);

