import React from 'react';
import { Card, Row, Col } from 'antd';
import Layout from 'components/layout';
import ImportData from '../components/import-data';

function Import() {
    return (
        <Layout>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card title="Import danych">
                        <ImportData />
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
}

export default Import;