import React from 'react';
import { Select } from "antd";

export default function ProductStatusSelect({ value, onChange, ...props }) {
  const handleChange = (newValue) => {
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  const options = [
    {
      value: 'sharpened',
      text: 'Gotowy'
    },
    {
      value: 'not_sharpened',
      text: 'Nowy'
    },
  ];

  return (
    <Select 
      value={props.defaultValue}
      onChange={handleChange}
      {...props}
    >
      {options.map(option => (
        <Select.Option key={option.value} value={option.value}>
          {option.text}
        </Select.Option>
      ))}
    </Select>
  );
}
