import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import axios from 'utils/axios';

export default function RegionSelect(props) {
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        if (regions.length === 0) {
            axios
                .get('/api/query/all-regions')
                .then(response => {
                    setRegions(response.data);
                    localStorage.setItem('regions', JSON.stringify(response.data));
                });
        }
    }, []);

    const data = regions.length === 0 && localStorage.getItem('regions') ? JSON.parse(localStorage.getItem('regions')) : regions;

    return (
        <Select
            placeholder="Wybierz region"
            loading={regions.length === 0}
            {...props}
            onChange={value => {
                const region = data.find(c => c.region === value);

                props.onChange && props.onChange(value);
                props.onChoose && props.onChoose(region);
            }}
        >
            {data.sort((a, b) => a.region.localeCompare(b.region)).map(region => <Select.Option key={`region-${region.id}`} value={region.region}>{region.region}</Select.Option>)}
        </Select>
    )
}
