import React, {useEffect, useState} from 'react';
import requiredAuth from "decorators/required-auth";
import Layout from 'components/layout';
import axios from 'utils/axios';
import List from 'components/list';
import {Button, Popconfirm, Space, Switch} from "antd";
import moment from 'moment';
import PaymentMethod from "components/payment-method";
import OrderStatus from "components/order/Status";
import NotesInput from "components/notes-input";
import {Link} from 'react-router-dom';
import OrderWorkshopStatus from "components/order-workshop-status";
import Filters, {convertToQueryString, getFilters} from "utils/filters";
import {useQueryParam} from "../utils/use-query-param";
import OrdersToolsList from "../components/orders-tools-list";
import config from "../config";

function Dashboard() {
    const [data, setData] = useState([]);
    const [page, setPage] = useQueryParam('page', 1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchProducts(page, getFilters())
    }, []);

    function fetchProducts(page, filters = {}) {
        setLoading(true);
        setPage(page);

        axios
            .get(`/api/query/get-orders-by-filters?page=${page+convertToQueryString(filters)}`)
            .then(response => {
                setData(response.data.data);
                setLastPage(response.data.last_page);
                setLoading(false);
            })
        ;
    }

    function DocumentList(props) {
        return <List
            name="documents"
            fields={['invoice_number', 'invoice_amount', 'paid']}
            render={{
                paid: (paid, record) => {
                    return parseInt(record.paid) === 1 ? 'Tak' : 'Nie';
                }
            }}
            labels={{
                invoice_number: 'Numer faktury',
                invoice_amount: 'Kwota',
                paid: 'Opłacona',
            }}
            data={props.data}
            hideActions={true}
        />
    }

    function handleDelete(order) {
        axios
            .post('/api/commands/order-archive', {
                orderId: order.id,
            })
            .then(response => {
                const _data = [...data];
                const missingIndex = _data.findIndex(o => o.id === order.id);
                _data.splice(missingIndex, 1);
                setData(_data);
            })
        ;
    }

    return (
        <Layout
            headerRightSide={() => <>
                <Link to="/orders/new">
                    <Button type="primary" style={{marginRight: 10}}>
                        Utwórz nowe zamówienie
                    </Button>
                </Link>

                <Filters
                    onChange={filters => fetchProducts(1, filters)}
                    renderBefore={[
                        {
                            name: 'showArchived',
                            defaultValue: false,
                            render: (setFilter, defaultValue) => (
                                <div style={{marginRight: 20, display: 'inline-block'}}>
                                    <label style={{marginRight: 10}}>Pokaż zarchiwizowane</label>
                                    <Switch defaultChecked={defaultValue} onChange={checked => setFilter(checked)} />
                                </div>
                            )
                        }
                    ]}
                />
            </>}
        >
            <List
                name="orders"
                fields={['order_index', 'customer_short_name', 'workshop_status', 'status', 'creation_date', 'deadline', 'actions']}
                render={{
                    notes: (notes, product) => <NotesInput defaultValue={notes} tool={product} />,
                    payment_method: (notes, method) => <PaymentMethod value={notes} />,
                    status: (status) => <OrderStatus value={status} />,
                    workshop_status: (status) => <OrderWorkshopStatus value={status} />,
                    deadline: (deadline, product) => deadline ? moment(deadline).format('DD-MM-YYYY') : '-',
                    creation_date: (deadline, product) => moment(deadline).format('DD-MM-YYYY'),
                    actions: (value, order) => (
                        <Space>
                            <Link to={`/orders/${order.id}`}>
                                <Button>Szczegóły</Button>
                            </Link>
                            <Button onClick={() => {
                                window.open(`${config.API_URL}/printout/order?orderId=${order.id}`);
                            }}>
                                Wydrukuj zlecenie
                            </Button>
                            {order.archived == 0 ? (
                                <Popconfirm title="Na pewno chcesz usunąć?" onConfirm={() => handleDelete(order)}>
                                    <Button type="danger">
                                        Archiwizuj
                                    </Button>
                                </Popconfirm>
                            ) : 'Zarchiwizowano'}
                        </Space>
                    )
                }}
                expandable={{
                    expandedRowRender: record => (
                        <>
                            <OrdersToolsList data={record.products} />
                            <DocumentList data={record.documents} />
                        </>
                    ),
                    rowExpandable: record => record.name !== 'Not Expandable',
                }}
                labels={{
                    order_index: 'Numer zamówienia',
                    deadline: 'Planowany termin realizacji',
                    payment_method: 'Sposób płatności',
                    creation_date: 'Data odbioru',
                    order_id: 'Identyfikator zamówienia',
                    customer_short_name: 'Klient',
                    status: 'Status zamówienia',
                    workshop_status: 'Status warsztatowy',
                    notes: 'Uwagi',
                    actions: 'Akcje',
                }}
                data={data}
                page={page}
                lastPage={lastPage}
                onChangePage={page => fetchProducts(page, getFilters())}
                loading={loading}
                rowKey="id"
                hideActions={true}
                alignRight={['actions']}
            />
        </Layout>
    )
}

export default requiredAuth(Dashboard);
