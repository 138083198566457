import React, {useState} from 'react';
import {Button, Dropdown, List, Menu, Space, Table, Tooltip} from 'antd';
import {CheckCircleOutlined, DownOutlined} from '@ant-design/icons';
import SignDocumentModal from 'modal/sign-document';
import axios from 'utils/axios';
import config from 'config';
import DocumentStatus from 'components/document-status';
import {orderUtils} from "../utils/order-utils";

export default function DocumentsList(props) {
    const [signModalUuid, setSignModalUuid] = useState(false);
    const [loadingDownloadPdfButton, setLoadingDownloadPdfButton] = useState(false);
    const [loadingSendPdfButton, setLoadingSendPdfButton] = useState(false);
    const [products, setProducts] = useState([]);

    let countDocumentProducts = 0;
    props.data.map(document => countDocumentProducts += document.products.length);

    function downloadPdf(id) {
        setLoadingDownloadPdfButton(true);
        orderUtils.downloadWZPdf(id, () => setLoadingDownloadPdfButton(false), () => setLoadingDownloadPdfButton(false));
    }

    function sendPdf(id) {
        setLoadingSendPdfButton(true);
        orderUtils.sendWZPdf(id, () => setLoadingSendPdfButton(false), () => setLoadingSendPdfButton(false));
    }

    const handleSign = (document) => {
        setProducts(document.products);
        setSignModalUuid(document.id);
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Numer faktury',
            dataIndex: 'invoice_number',
            key: 'invoice_number'
        },
        {
            title: 'Kwota faktury',
            dataIndex: 'invoice_amount',
            key: 'invoice_amount'
        },
        {
            title: `Produkty (${countDocumentProducts} z ${props.countProducts})`,
            dataIndex: 'products',
            key: 'products',
            align: 'right',
            render: (value, document) => document.products.length
        },
        {
            title: 'Odebrano',
            dataIndex: 'received',
            key: 'received',
            align: 'right',
            render: (value, document) => {
                return <DocumentStatus status={parseInt(value)} date={document.received_at} onToggle={(value) => axios.post('/api/commands/change-document-received-status', {document_id: document.id, received: value})} />;
            }
        },
        {
            title: 'Opłacone',
            dataIndex: 'paid',
            key: 'paid',
            align: 'right',
            render: (value, document) => {
                return <DocumentStatus status={parseInt(value)} date={document.paid_at} onToggle={(value) => axios.post('/api/commands/change-document-paid-status', {document_id: document.id, paid: value})} />;
            }
        },
        {
            title: 'Podpis',
            dataIndex: 'signature',
            key: 'signature',
            align: 'right',
            render: (value, document) => value ? <img src={`${config.API_URL+value.path}`} style={{maxWidth: 130}}/> : <Button onClick={() => handleSign(document)}>Podpisz</Button>
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            align: 'right',
            render: (value, document) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="1" onClick={() => downloadPdf(document.id)}>Pobierz</Menu.Item>
                        <Menu.Item key="2" onClick={() => sendPdf(document.id)}>Wyślij do klienta</Menu.Item>
                    </Menu>
                );

                return (
                    <Space>
                        <Dropdown overlay={menu}>
                            <Button loading={loadingDownloadPdfButton || loadingSendPdfButton}>
                                Dokument WZ <DownOutlined />
                            </Button>
                        </Dropdown>

                        {parseInt(document.sent) === 1 ? (
                            <Tooltip title="Dokument został wysłany">
                                <CheckCircleOutlined style={{color: '#6ed349'}} />
                            </Tooltip>
                        ) : null}
                    </Space>
                )
            }
        },
    ];

    function DocumentProducts(props) {
        return (
            <List
                bordered
                dataSource={props.data}
                renderItem={item => (
                    <List.Item>
                        {item.name}
                    </List.Item>
                )}
            />
        )
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                expandable={{
                    expandedRowRender: record => <DocumentProducts data={record.products} />,
                    rowExpandable: record => record.name !== 'Not Expandable',
                }}
                size="small"
                pagination={false}
                rowKey="id"
            />

            <SignDocumentModal
                title="Podpisz dokument"
                id={signModalUuid}
                onOk={() => null}
                onCancel={() => setSignModalUuid(false)}
                destroyOnClose={true}
                afterClose={() => props.triggerRefresh ? props.triggerRefresh() : null}
                width={650}
                products={products}
             />

        </>
    )
}
