import React, { useState, useRef } from 'react';
import { Button, Radio, Space, message, Table, Tag } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'utils/axios';

export default function ImportData() {
    const [loading, setLoading] = useState(false);
    const [importType, setImportType] = useState('customers');
    const [delimiter, setDelimiter] = useState(',');
    const [results, setResults] = useState([]);
    const fileInputRef = useRef(null);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        
        if (!file) return;
        
        if (!file.name.endsWith('.csv')) {
            message.error('Możesz wgrać tylko plik CSV!');
            return;
        }

        setLoading(true);
        setResults([]); // Reset results on new upload

        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', importType);
        formData.append('delimiter', delimiter);

        try {
            const response = await axios.post('/api/commands/import-data', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data.error) {
                message.error(response.data.error);
                setResults([{ row: '-', status: 'error', message: response.data.error }]);
            } else {
                const importResults = response.data.results;
                setResults(importResults);
                
                const errors = importResults.filter(r => r.status === 'error');
                const successes = importResults.filter(r => r.status === 'success');
                
                message.info(
                    `Import zakończony: ${successes.length} poprawnych, ${errors.length} błędów`
                );
            }
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'Błąd podczas importu';
            message.error(errorMessage);
            setResults([{ row: '-', status: 'error', message: errorMessage }]);
        } finally {
            setLoading(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const triggerFileInput = () => fileInputRef?.current?.click();

    const resultsColumns = [
        {
            title: 'Wiersz',
            dataIndex: 'row',
            key: 'row',
            width: '80px',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '120px',
            render: (status) => (
                <Tag color={status === 'success' ? 'success' : 'error'}>
                    {status === 'success' ? 'Sukces' : 'Błąd'}
                </Tag>
            ),
        },
        {
            title: 'Szczegóły',
            dataIndex: 'message',
            key: 'message',
            render: (message, record) => (
                record.status === 'success' ? 
                    'Poprawnie zaimportowano' : 
                    message
            ),
        }
    ];

    const getImportTemplate = () => {
        if (importType === 'customers') {
            return 'id,short_name,name,email,phone_number,address_line_1,address_line_2,postcode,city,country,manager_email,region,default_due_date_days,payment_method,send_email,send_wz,send_pz';
        }
        return 'id,name,symbol,category_id';
    };

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Radio.Group 
                value={importType} 
                onChange={e => setImportType(e.target.value)}
            >
                <Radio.Button value="customers">Klienci</Radio.Button>
                <Radio.Button value="products">Produkty</Radio.Button>
            </Radio.Group>

            <Radio.Group 
                value={delimiter} 
                onChange={e => setDelimiter(e.target.value)}
            >
                <Radio.Button value=",">Przecinek (,)</Radio.Button>
                <Radio.Button value=";">Średnik (;)</Radio.Button>
                <Radio.Button value="\t">Tabulator</Radio.Button>
            </Radio.Group>
            
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                accept=".csv"
                style={{ display: 'none' }}
            />
            
            <Space>
                <Button 
                    icon={<UploadOutlined />} 
                    loading={loading}
                    onClick={triggerFileInput}
                    type="primary"
                >
                    Importuj {importType === 'customers' ? 'klientów' : 'produkty'}
                </Button>

                <Button
                    onClick={() => {
                        const element = document.createElement('a');
                        const file = new Blob([getImportTemplate()], {type: 'text/csv'});
                        element.href = URL.createObjectURL(file);
                        element.download = `szablon_${importType}.csv`;
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                    }}
                >
                    Pobierz szablon CSV
                </Button>
            </Space>

            {results.length > 0 && (
                <div style={{ marginTop: 16 }}>
                    <h3>Wyniki importu</h3>
                    <Table
                        columns={resultsColumns}
                        dataSource={results}
                        pagination={false}
                        rowKey={(record, index) => index}
                        rowClassName={(record) => 
                            record.status === 'success' ? 'table-row-success' : 'table-row-error'
                        }
                    />
                </div>
            )}

            <style jsx="true">{`
                .table-row-success {
                    background-color: #f6ffed;
                }
                .table-row-success:hover > td {
                    background-color: #d9f7be !important;
                }
                .table-row-error {
                    background-color: #fff1f0;
                }
                .table-row-error:hover > td {
                    background-color: #ffa39e !important;
                }
            `}</style>
        </Space>
    );
} 